import THEME from '../theme';
import { aspectRatioLogoAbout, borderRadiusButtons, linkAppleStore, linkPlayStore, footerSocialData } from '../../config/data';

const breakpoint = 1080;
const colors = [THEME.COLORS.PRIMARY_900, THEME.COLORS.PRIMARY_700]; // Define an array of background colors and text colors

//******************************************************************
// To add gradient background to section, add above containerStyles:: 
// gradientColors = {
//     colors: [
//         'rgba(68, 155, 206, 1)',
//         'rgba(19, 63, 112, 1)',
//         'rgba(19, 23, 54, 1)',
//     ],
//     start: { x: 0, y: 0 },
//     end: { x: 1, y: 0 },
// };
// gradientBackgroundStyles = {
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     top: 0,
//     bottom: 0,
// };


// To add image background to section, add above containerStyles: 
// backgroundImage: {
//     mobile: {
//         uri: require("../../../assets/FotoAboutMobile.jpg")
//     },
//     web: {
//         uri: require("../../../assets/FotoAbout.jpg")
//     },
// },


// To add solid color background to section, add in containerStyles: 
// containerStyles = (width) => ({
//     flexBasis: "auto",
//     backgroundColor: "green" // (Optional) The solid color to use as the background
// });

//******************************************************************

const instagramLink = footerSocialData.find(obj => obj.name === "instagram").link;
const instagramName = footerSocialData.find(obj => obj.name === "instagram").profile;
const whatsappLink = footerSocialData.find(obj => obj.name === "whatsapp").link;
const whatsappNumber = footerSocialData.find(obj => obj.name === "whatsapp").number;


export const clientConfig = [
    {
        name: "bia-rosolino-yoga",
        pages: [
            {
                name: "about",
                sections: [
                    {
                        header: {
                            //add a logo property if there is a logo at header
                            logo: {
                                style: (width) => ({
                                    aspectRatio: aspectRatioLogoAbout,
                                    resizeMode: "contain",
                                    paddingVertical: width < breakpoint ? "1rem" : "1.5rem",
                                    marginHorizontal: width < breakpoint ? "1rem" : "1.5rem",
                                    marginTop: "1rem",
                                }),
                                uri: require('../../../assets/LogoAbout.png'),
                            },
                            buttonLogin:
                            {
                                style: (width) => ({
                                    padding: "0.3rem",
                                    marginTop: "1rem"
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                    paddingHorizontal: width < breakpoint ? "0rem" : "1rem",
                                    paddingLeft: width < breakpoint ? "0.5rem" : "1rem"
                                }),
                            },
                            buttonApp: {
                                style: (width) => ({
                                    backgroundColor: 'transparent',
                                    padding: "0.5rem",
                                    borderRadius: borderRadiusButtons,
                                    paddingVertical: 8,
                                    paddingHorizontal: "1rem",
                                    marginTop: "1rem",
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                }),
                            }
                        },
                        columns: [
                            // {
                            //     style: (width, columnWidth) => ({
                            //         flexDirection: 'row',
                            //         justifyContent: 'space-between',
                            //         alignItems: 'center',
                            //         padding: width < breakpoint ? "1rem" : "2rem",
                            //         width: width < breakpoint ? '100%' : "50%",
                            //         alignItems: "center",
                            //         order: width < breakpoint ? 2 : 2,
                            //     }),
                            //     items: [
                            //         {
                            //             type: 'image',
                            //             uri: require('../../../assets/Computador.png'),
                            //             aspectRatio: 1.25, //padrão da imagem
                            //             styles: (width) => ({
                            //                 container: {
                            //                     width: width < breakpoint ? "100%" : "100%",
                            //                     marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                            //                     marginTop: width < breakpoint ? "0.5rem" : "3rem",
                            //                 },
                            //                 image: {
                            //                     width: '100%',
                            //                     resizeMode: "contain",
                            //                 },
                            //             }),
                            //         },
                            //     ]
                            // },
                            {
                                style: (width) => ({
                                    justifyContent: 'center',
                                    flex: 1,
                                    alignItems: width < breakpoint ? 'center' : 'center',
                                    paddingTop: width < breakpoint ? "0rem" : "0rem",
                                    paddingBottom: width < breakpoint ? "2rem" : "2rem",
                                    order: width < breakpoint ? 1 : 1,
                                    width: width < breakpoint ? "100%" : "100%",
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/LogoAbout2.png'),
                                        aspectRatio: aspectRatioLogoAbout,
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "70%",
                                                marginBottom: width < breakpoint ? "4rem" : "2rem",
                                                marginTop: width < breakpoint ? "5rem" : "5rem",
                                                alignItems: width < breakpoint ? "center" : "center",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                    {
                                        type: 'text',
                                        text: 'Seu caminho para o autoconhecimento começa aqui!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 20,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'VEM DAR UM RESPIRO EM MEIO AO CAOS.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 24,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            fontWeight: 'bold'
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'QUERO FAZER PARTE',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? 14 : 18,
                                            marginBottom: width < breakpoint ? "1rem": "2rem",
                                            paddingHorizontal: width < breakpoint ? 20 : 36,
                                            borderRadius: borderRadiusButtons,
                                            alignSelf: width < breakpoint ? "center" : "center",
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 12 : 16,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "center" : "center",
                                        }),
                                    },
                                ]
                            }
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/FotoAboutMobile.jpg")
                            },
                            web: {
                                uri: require("../../../assets/FotoAbout.jpg")
                            },
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            //backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "1rem" : "3rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'TENHO UM CONVITE PARA VOCÊ!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 30 : 44,
                                            color: THEME.COLORS.PRIMARY_900,
                                            textAlign: "center",
                                            marginBottom: 35,
                                            marginTop: 50,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Essa plataforma é uma consolidação de tudo que venho vivendo e estudando ao longo da minha jornada como aluna e professora de yoga.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 17 : 25,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 20,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "0rem",
                                        })
                                    },
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/FotoAboutMe.jpg'),
                                        aspectRatio: 1, //AJUSTAR CONFORME IMAGEM
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "40%",
                                                marginBottom: "2rem",
                                                marginTop: width < breakpoint ? "0.5rem" : "2rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "cover",
                                                borderRadius: '50%',
                                            },
                                        }),
                                    },
                                    {
                                        type: 'text',
                                        text: 'Meu objetivo é ajudar as pessoas a se (re)conhecerem usando o yoga como ferramenta.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 17 : 25,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 20,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "0rem",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Eu convido você a dar um respiro profundo, a olhar para dentro, descobrir e cultivar novos espaços internos.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 17 : 25,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "0rem",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Eu convido você a conhecer sua luz mas também a sua sombra, a viver o yoga dentro e fora do tapetinho, a cuidar do seu corpo, da sua mente e das suas emoções.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 17 : 25,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "0rem",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'E te auxilio em toda essa jornada! Vamos juntes?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 22 : 30,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontWeight: 'bold',
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "0rem",
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero praticar agora!',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? '1rem' : '1.5rem',
                                            marginBottom: 10,
                                            paddingHorizontal: width < breakpoint ? '2rem' : '4rem',
                                            borderRadius: borderRadiusButtons,
                                            alignSelf: "center",
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 25,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            fontWeight: 'bold',
                                            textAlign: "center",
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingHorizontal: width < breakpoint ? "2rem" : "10rem",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "0rem",
                                    width: width < breakpoint ? '100%' : "50%",
                                    alignItems: "center",
                                    order: width < breakpoint ? 2 : 1,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/FotoAppStore.jpg'),
                                        aspectRatio: 0.56, //de acordo com a imagem
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "60%",
                                                marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                                                marginTop: width < breakpoint ? "0.5rem" : "3rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "0rem",
                                    order: width < breakpoint ? 1 : 2,
                                    width: width < breakpoint ? "100%" : "50%",
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Disponível nas lojas de aplicativos!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 34,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                            paddingTop: width < breakpoint ? "2rem" : "0rem"
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'É só escolher um plano de assinatura ou curso abaixo e baixar o APP BIA ROSOLINO YOGA para ter acesso a um mundo de yoga.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 20 : 28,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Leve sua prática, aulas de filosofia, workshops, meditações, exercícios de respiração e muito mais pra qualquer lugar do mundo!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 24,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        })
                                    },
                                    {
                                        type: 'appleStoreButton',
                                        uri: linkAppleStore,
                                    },
                                    {
                                        type: 'googlePlayButton',
                                        uri: linkPlayStore,
                                    },
                                ]
                            }
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.PRIMARY_700
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "2rem" : "5rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "1rem" : "5rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        content: [
                                            {
                                                text: 'O que você vai encontrar no', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 40,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'APLICATIVO', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 45,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'imageCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        info: [
                                            {
                                                title: "Como funciona",
                                                infos: "Esse é um módulo gratuito, que qualquer pessoa pode acessar para conhecer mais sobre o yoga que estudamos aqui. Tem uma aula apresentando a professora, a metodologia, os estilos de prática, como funciona a plataforma e tem até uma prática bônus. A ideia é que você possa conectar um pouquinho com a nossa energia e guiança para sentir se conecta com a sua :)",
                                                image: require("../../../assets/FotoCategoria1.jpg"),
                                            },
                                            {
                                                title: "Primeiros passos",
                                                infos: "Um guia para você aprender os fundamentos e bases do yoga para facilitar seu caminho, seus estudos e práticas. Se você não conhece muita coisa ainda e se sente perdido, esse módulo é para você. Aqui passaremos pelo passo a passo para que você se sinta preparado para começar sua jornada com yoga.",
                                                image: require("../../../assets/FotoCategoria11.jpg"),
                                            },
                                            {
                                                title: "Aulas ao vivo",
                                                infos: "Aulas online pela plataforma Zoom. Para quem deseja um olhar atento e cuidadoso com a prática, um contato mais próximo e profundo com a professora para possíveis ajustes e correções. \nTurma coletiva para todos os níveis e corpos. Temas diferentes a cada mês. Calendário com aulas de Vinyasa, Yin, Rocket, Slow Flow e Workshops.",
                                                image: require("../../../assets/FotoCategoria2.jpg"),
                                            },
                                            {
                                                title: "Aulas gravadas",
                                                infos: "Várias práticas de diferentes estilos e durações. Aqui você encontra aulas completas de vinyasa (mais fluida), hatha (mais permanência), rocket (série fixa mais vigorosa), yin e slow flow (mais suaves e meditativas). Você também pode escolher uma prática nutritiva, para diferentes momentos do dia a dia, que vão te ajudar como por exemplo quando está com dor de cabeça, com torcicolo ou com cólicas menstruais. E se você não tem muito tempo e quer fazer uma prática mesmo assim, tem mais de 50 aulas curtinhas, que chamamos de pocket, para você incluir na sua rotina :)",
                                                image: require("../../../assets/FotoCategoria3.jpg"),
                                            },
                                            {
                                                title: "Yoga para gestantes",
                                                infos: "Se você está gestando, aqui também tem um plano de aulas específicas para esse momento da sua vida. Yoga na gestação não é só sobre posturas e movimentos físicos, é um momento de conexão com seu corpo, que está se transformando e que virou casa. É sobre abrir novos caminhos internos, criar repertório, intimidade e confiança pro momento do parto. É um espaço para se nutrir, se acolher e se (re)conhecer. Momento de conexão com você, com seu bebê e com outras gestantes. Aulas ao vivo no zoom 1 x por semana e acesso a várias aulas gravadas, cada uma com um tema diferente, para trabalhar o corpo físico, mas também o mental e emocional. Você também encontra aqui respirações, relaxamentos guiados e meditações.",
                                                image: require("../../../assets/FotoCategoria13.jpg"),
                                            },
                                            {
                                                title: "Jornadas",
                                                infos: "Diferentes caminhos em direção ao autoconhecimento. São várias jornadas, com aulas teóricas, apostilas e práticas para ser seu guia. Através de simbologias como os 4 elementos da natureza, os 7 chakras, o sol e a lua, a mitologia hindu e várias outras, vamos estudando sobre a existência, praticando yoga dentro do tapete e levando pra vida fora dele. Para quem deseja aprender mais a fundo sobre as posturas, temos 2 jornadas completas que além de ensinar a parte técnica, como ajustes e alinhamentos, também nos ensina a história e simbologia de cada uma das posturas. É só escolher o caminho que seu coração mais gosta e mergulhar fundo junto com a gente!",
                                                image: require("../../../assets/FotoCategoria4.jpg"),
                                            },
                                            {
                                                title: "Tutoriais de posturas",
                                                infos: "Aqui você aprende a executar e alinhar as principais posturas do yoga. Desde as mais simples, até as mais complexas. Te dou dicas que vão te ajudar a montar as posturas com segurança e consciência de acordo com o que seu corpo te permite.",
                                                image: require("../../../assets/FotoCategoria5.jpg"),
                                            },
                                            {
                                                title: "Trainings (Workshops)",
                                                infos: "Oficinas de posturas com aulas mais técnicas, exercícios educativos, de fortalecimento, flexibilidade, mobilidade, liberação miofascial, massagem, movimentos que vão agregar na construção de um corpo mais saudável. Além de aulas com professores convidados e especialistas em diferentes temas.",
                                                image: require("../../../assets/FotoCategoria6.jpg"),
                                            },
                                            {
                                                title: "Filosofia & Fundamentos",
                                                infos: "Yoga é muito mais do que só posturas físicas. Dentro da nossa metodologia, a cada mês mergulhamos nesse rico e profundo mundo da filosofia do yoga, através dos diferentes temas com as aulas teóricas, e-books, reflexões e textos complementares. Para você que gosta de se aprofundar, tem um módulo inteiro com todos os temas filosóficos que já estudamos por aqui. Além disso, os fundamentos do yoga são essenciais para nortear e nos trazer mais consciência para a prática e para viver o yoga na vida real. Aqui vocês encontram aulas que vão agregar esse conhecimento também.",
                                                image: require("../../../assets/FotoCategoria7.jpg"),
                                            },
                                            {
                                                title: "Meditação e Mantra",
                                                infos: "Olhar para dentro. Sair do piloto automático, viver com inteireza e presença. Trazer a mente para casa. E assim estar cada vez em maior contato com a sua essência e propósito. Através de mantras e meditações guiadas gravadas e nas aulas ao vivo, vamos criando o hábito de meditar.",
                                                image: require("../../../assets/FotoCategoria8.jpg"),
                                            },
                                            {
                                                title: "Pranayama",
                                                infos: "Expansão da energia vital. Técnicas e exercícios respiratórios novas a cada mês. O yoga nos ensina a respirar da forma correta, ampliando nossa capacidade pulmonar. Respirar é viver. Yoga nos ensina a viver melhor.",
                                                image: require("../../../assets/FotoCategoria9.jpg"),
                                            },
                                            {
                                                title: "Relaxamento guiado",
                                                infos: "Uma técnica milenar, profunda e muito efetiva do yoga para ajudar o corpo e a mente a relaxarem. Através do yoga nidra (relaxamento guiado) acessamos um estado consciente de sono profundo, nos ajudando a eliminar tensões, reprogramar nossa mente e emoção e nos preparar para viver a vida de forma mais leve. Nesse módulo te convido a relaxar e te guio através de várias histórias e conduções diferentes, sempre orientando a olhar para dentro e se conhecer um pouquinho melhor.",
                                                image: require("../../../assets/FotoCategoria14.jpg"),
                                            },
                                            {
                                                title: "Aulas com Profs Convidados",
                                                infos: "Para agregar mais conhecimento para a plataforma e complementar nossos estudos, temos aulas de diferentes temas com professores convidados. Aqui abordamos aulas prática/oficina/workshops, desenvolvendo técnicas para melhorar a parte física (posturas) e também aulas teóricas, de filosofia e estudos complementares. Todos os professores convidados são de extrema confiança e admiração da professora Bia Rosolino.",
                                                image: require("../../../assets/FotoCategoria12.jpg"),
                                            },
                                            {
                                                title: "Cursos Avulsos",
                                                infos: "Além dos planos de assinatura de aulas gravadas ou aulas ao vivo, você pode começar pequeno com cursos avulsos. Se você tem pouco tempo ou investimento essas são ótimas opções para você!",
                                                image: require("../../../assets/FotoCategoria10.jpg"),
                                            },
                                        ],
                                        cardStyle: (width) => ({
                                            width: "16rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: "9rem",
                                            borderRadius: "12px",
                                        }),
                                        titleStyle: (width) => ({
                                            fontWeight: "bold",
                                            paddingVertical: "1rem",
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: THEME.FONTSIZE.EXTRASMALL,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                    },
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'iconCards',
                                        color: THEME.COLORS.PRIMARY_900,
                                        titleStyle: (width) => ({
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        descriptionStyle: (width) => ({
                                            fontSize: 18,
                                            textAlign: "center",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        cardData: [
                                            {
                                                icon: 'shield-checkmark-outline',
                                                title: 'PAGAMENTO SEGURO',
                                                description: 'Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.',
                                            },
                                            {
                                                icon: 'calendar',
                                                title: 'ACESSO IMEDIATO',
                                                description: 'Após o pagamento, você receberá acesso ao conteúdo comprado no aplicativo.',
                                            },
                                            {
                                                icon: 'medal',
                                                title: '7 DIAS DE GARANTIA',
                                                description: 'Você poderá pedir a devolução de 100% do seu dinheiro dentro deste prazo.',
                                            },
                                        ]
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            padding: "3rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'text',
                                        text: 'ESCOLHA SEU PLANO DE ASSINATURA',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 50,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            paddingVertical: width < breakpoint ? '2rem' : '2rem',
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Diferentes planos de assinatura com renovação automática. Pagamento no boleto ou cartão de crédito. Você tem 7 dias grátis em qualquer opção, para testar e ver se gosta. Opção de fazer pelo celular (aplicativo Bia Rosolino Yoga), notebook ou tablet.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 14 : 20,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            paddingBottom: 20,
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'plansCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                                            darker: THEME.COLORS.PRIMARY_900
                                        },
                                        cardStyle: (width, index) => ({
                                            width: width < breakpoint ? width * 0.8 : width * 0.28,
                                            height: "100%",
                                            borderWidth: "4px",
                                            borderStyle: "solid",
                                            borderColor: colors[(index + 1) % colors.length],
                                            backgroundColor: colors[index % colors.length], // Alternate the background color based on the index
                                        }
                                        ),
                                        titleStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 34 : 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                        }),
                                        pricingStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 30 : 38,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        }),
                                        infoStyle: (width, index) => ({
                                            textAlign: "left",
                                            fontSize: width < breakpoint ? 14 : 16,
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        buttonStyle: (width, index) => ({
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                            style: {
                                                borderRadius: borderRadiusButtons,
                                                paddingVertical: width < breakpoint ? 14 : 18,
                                            },
                                            titleStyle: {
                                                fontWeight: 'bold',
                                                color: colors[index % colors.length], // Alternate the text color based on the index
                                                fontFamily: THEME.FONTFAMILY.TITLE,
                                                fontSize: width < breakpoint ? 16 : 22,
                                            },
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.PRIMARY_900,
                            paddingVertical: "2rem"
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "1rem" : "5rem",
                                    paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        content: [
                                            {
                                                text: 'Você também pode comprar os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 40,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'CONTEÚDOS SEPARADAMENTE', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 45,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'para ter os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'RESULTADOS', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'QUE VOCÊ', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'DESEJA', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'coursesCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        cardStyle: (width) => ({
                                            width: "18rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: '10.12rem',
                                            borderRadius: '12px',
                                        }),
                                        titleStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        priceStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            paddingBottom: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 15,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 22,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                    },
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'PRECISA DE AJUDA PARA DECIDIR?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 30,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.PRIMARY_900,
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: 50,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Converse comigo, da forma que você preferir:',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 24 : 28,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            width: "100%",
                                            textAlign: "center",
                                            marginBottom: 20,
                                            marginTop: 5,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'iconCards',
                                        color: THEME.COLORS.PRIMARY_900,
                                        titleStyle: (width) => ({
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        descriptionStyle: (width) => ({
                                            fontSize: 16,
                                            textAlign: "center",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        cardData: [
                                            {
                                                icon: 'logo-whatsapp',
                                                title: 'Fale comigo por WhatsApp',
                                                description: whatsappNumber,
                                                uri: whatsappLink,
                                            },
                                            {
                                                icon: 'logo-instagram',
                                                title: 'Instagram',
                                                description: instagramName,
                                                uri: instagramLink,
                                            },
                                            // add more icons here
                                            // {
                                            //     icon: 'mail',
                                            //     title: 'Atendimento por E-mail',
                                            //     description: 'contato@blixtecnologia.com.br',
                                            // },
                                        ],
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Perguntas frequentes',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 25 : 34,
                                            color: THEME.COLORS.PRIMARY_900,
                                            textAlign: "center",
                                            textDecorationLine: "underline",
                                            textDecorationColor: THEME.COLORS.PRIMARY_900,
                                            marginBottom: 8,
                                            marginTop: 50,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'FAQ',
                                        questionStyle: (width, expandedId, question) => ({
                                            flex: 1,
                                            fontSize: width < breakpoint ? 16 : 18,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            color: expandedId === question.id ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.TEXT_ABOUT
                                        }),
                                        answerStyle: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 18,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            color: THEME.COLORS.TEXT_ABOUT
                                        }),
                                        containerStyle: (width) => ({
                                            backgroundColor: 'transparent',
                                            elevation: 5,
                                            margin: 10,
                                            overflow: 'hidden',
                                            borderBottomWidth: 1.5,
                                            borderBottomColor: THEME.COLORS.TEXT_ABOUT,
                                        }),
                                        questions: linkPlayStore !== null && linkAppleStore !== null ? [
                                            {
                                                id: 1,
                                                question: 'Tem aplicativo exclusivo?',
                                                answer: 'Tem sim! Você pode baixar o aplicativo nas lojas Apple Store ou Google Play em seu celular.',
                                            },
                                            {
                                                id: 2,
                                                question: 'Como faço para comprar um produto no aplicativo?',
                                                answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                                            },
                                            {
                                                id: 3,
                                                question: 'Como faço para acessar os conteúdos?',
                                                answer: 'Você pode acessar o conteúdo por aqui ou pelo aplicativo baixado nas lojas, basta clicar no botão “Visualizar Conteúdo”.',
                                            },
                                            {
                                                id: 4,
                                                question: 'Quais são as formas de pagamento aceitas?',
                                                answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                                            },
                                            {
                                                id: 5,
                                                question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                                                answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” no aplicativo que você baixou ou acessar por aqui.',
                                            },
                                            {
                                                id: 6,
                                                question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                                                answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                                            },
                                            {
                                                id: 7,
                                                question: 'Como faço para me cadastrar no aplicativo? ',
                                                answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                                            },
                                            {
                                                id: 8,
                                                question: 'Consigo cancelar a qualquer momento?',
                                                answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral, semestral ou anual), o cancelamento deve ser solicitado formalmente.',
                                            },
                                            {
                                                id: 9,
                                                question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                                                answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                                            },
                                        ] : [
                                            {
                                                id: 2,
                                                question: 'Como faço para comprar um produto no aplicativo?',
                                                answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                                            },
                                            {
                                                id: 3,
                                                question: 'Como faço para acessar os conteúdos?',
                                                answer: 'Você pode acessar o conteúdo por aqui, basta clicar no botão “Visualizar Conteúdo”.',
                                            },
                                            {
                                                id: 4,
                                                question: 'Quais são as formas de pagamento aceitas?',
                                                answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                                            },
                                            {
                                                id: 5,
                                                question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                                                answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” e acessar os conteúdos desbloqueados.',
                                            },
                                            {
                                                id: 6,
                                                question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                                                answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                                            },
                                            {
                                                id: 7,
                                                question: 'Como faço para me cadastrar no aplicativo? ',
                                                answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                                            },
                                            {
                                                id: 8,
                                                question: 'Consigo cancelar a qualquer momento?',
                                                answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral, semestral ou anual), o cancelamento deve ser solicitado formalmente.',
                                            },
                                            {
                                                id: 9,
                                                question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                                                answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                                            },
                                        ]
                                    }
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingHorizontal: width < breakpoint ? "2rem" : "10rem",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 paddingVertical: width < breakpoint ? "2rem" : "5rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'DEPOIMENTOS',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 26 : 38,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 20,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE,
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'testimonials',
                    //                     arrowBackgroundColor: {
                    //                         lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                    //                         darker: THEME.COLORS.PRIMARY_800
                    //                     },
                    //                     info: [
                    //                         {
                    //                             title: "~ John",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                    //                             //image: require("../../../assets/Testimonial1.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Kamesh",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial2.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Nobita",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial3.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Juliet",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial4.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Peter",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial5.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Lia",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                    //                             //image: require("../../../assets/Testimonial6.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                     ],
                    //                     cardStyle: (width) => ({
                    //                         width: "20rem",
                    //                         height: '100%',
                    //                         marginHorizontal: "2rem",
                    //                         backgroundColor: "transparent",
                    //                     }),
                    //                     titleStyle: (width) => ({
                    //                         paddingVertical: "1rem",
                    //                         textAlign: "center",
                    //                         fontSize: THEME.FONTSIZE.STANDARD,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE
                    //                     }),
                    //                     infoStyle: (width) => ({
                    //                         textAlign: "center",
                    //                         padding: "0.5rem",
                    //                         fontSize: THEME.FONTSIZE.SMALL,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'ENTRE NA NOSSA COMUNIDADE DE YOGA!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 24,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginTop: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero testar por 7 dias grátis',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? '1rem' : '1rem',
                                            paddingHorizontal: width < breakpoint ? '2rem' : '4rem',
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            alignSelf: width < breakpoint ? "center" : "center",
                                            marginBottom: 40,
                                            marginTop: 40,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "flex-end" : "center",
                                        }),
                                    }
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },

                    // Add more sections for this client here
                ],
            },
            {
                name: "plans",
                sections: [
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'text',
                                        text: 'ESCOLHA SEU PLANO DE ASSINATURA',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 50,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            paddingVertical: width < breakpoint ? '2rem' : '2rem',
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Diferentes planos de assinatura com renovação automática. Pagamento no boleto ou cartão de crédito. Você tem 7 dias grátis em qualquer opção, para testar e ver se gosta. Opção de fazer pelo celular (aplicativo Bia Rosolino Yoga), notebook ou tablet.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 14 : 20,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            paddingBottom: 20,
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'plansCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                                            darker: THEME.COLORS.PRIMARY_900
                                        },
                                        cardStyle: (width, index) => ({
                                            width: width < breakpoint ? width * 0.8 : width * 0.28,
                                            height: "100%",
                                            borderWidth: "4px",
                                            borderStyle: "solid",
                                            borderColor: colors[(index + 1) % colors.length],
                                            backgroundColor: colors[index % colors.length], // Alternate the background color based on the index
                                        }
                                        ),
                                        titleStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 34 : 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                        }),
                                        pricingStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 30 : 38,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        }),
                                        infoStyle: (width, index) => ({
                                            textAlign: "left",
                                            fontSize: width < breakpoint ? 14 : 16,
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        buttonStyle: (width, index) => ({
                                            color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                                            style: {
                                                borderRadius: borderRadiusButtons,
                                                paddingVertical: width < breakpoint ? 14 : 18,
                                            },
                                            titleStyle: {
                                                fontWeight: 'bold',
                                                color: colors[index % colors.length], // Alternate the text color based on the index
                                                fontFamily: THEME.FONTFAMILY.TITLE,
                                                fontSize: width < breakpoint ? 16 : 22,
                                            },
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            flex: 1,
                            backgroundColor: THEME.COLORS.PRIMARY_900,
                            paddingVertical: "2rem"
                        }),
                    },
                ],
            },
            // add more pages as needed
        ],
    },
    // add more clients as needed
];
